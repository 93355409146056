export const environment = {
  production: true,
  // simServiceUrl: 'http://172.36.13.212:9000/external/products'
  // simServiceUrl: 'https://interop.esp-staging.vmware-aws.com/external',
   simServiceUrl: 'https://interop.esp.spespg1.vmw.saas.broadcom.com/external',
  //  simServiceUrl: 'https://apigw-sbx.vmware.com/internal/dev12/v1/m4/api/sim-prodction',
  // simServiceUrl: 'https://apigw-test.vmware.com/uat2/v1/m4/api/SIM-Internal',
  agentUrl:"/apidataProd",
  feedbackUrl: 'https://feedback.esp.vmware.com/assets/ipf/esp.js',
  // feedbackUrl: 'https://lumos.vmware.com/assets/lumos.js',
  feedbackFormName: 'vmwesp-prod-69-ebzzx',
  authUrl: 'https://console.cloud.vmware.com/csp/gateway',
  logoutUrl: 'https://auth.esp.vmware.com/api/auth/v2/logout',
  orgId:"550e216c-590e-4546-b65e-4509607ac112",
  codeChallenge:"tiPPjtzoVP8SX_jTS9FQob1SM2O5O90FErzqvKY3vnM",
  codeVerifier:"GVND5PdjpdDoSq2P6669Cw2kUPedIRs4D7WQiGJnVfDrR-qIaWtR_xInz59iAG5EaurbPGKBO6EaBuY--1to-QByfQaF0KFvfCuqTv8xKnwfJlSOpf8duH4dRbfmJaX8",
  // authClientId: 'vmwesp-stg-69-sfkjw',
  // authClientId: 'esp-decc-stg-28-iraay',
  authClientId: 'vmwesp-prod-69-ebzzx',
  expireTime: 30 * 60 * 60 //30 mins
};
